import { UrlLink } from '@/configs/urlLink';
import React from 'react';
import Link from 'next/link';
import bgAuthor from '@/assets/images/bg-author.png';
import LazyImage from '../shared/LazyImage';
import { ILecturer } from '@/interfaces/Author';

export interface CardPlaylistProps {
  data?: ILecturer;
  isLoading: boolean;
  onClick?: () => void;
  isHomePage?: boolean;
}
const CardPlaylistSkeleton = (
  <div className="bg-gray-variants-2 flex h-full w-56 animate-pulse flex-col gap-4 rounded-2xl p-4">
    <div className="bg-gray-variants-8 h-48" />
    <div className="bg-gray-variants-8 h-6 w-1/2 rounded-lg" />
    <div className="bg-gray-variants-8 h-6 w-full rounded-lg" />
  </div>
);
const AuthorCard = ({ data, isLoading, isHomePage }: CardPlaylistProps) => {
  return (
    <>
      {isLoading ? (
        CardPlaylistSkeleton
      ) : (
        <Link
          className={`flex ${
            isHomePage
              ? 'h-[472px] md:h-[462px]'
              : 'h-[424px]  md:h-[390px] lg:h-[442px]'
          } cursor-pointer flex-col rounded-[4px] border-[1px] border-[#DCDEE0] duration-150 hover:scale-105`}
          href={`${UrlLink.knowledge.author}/${data?.slug}`}
        >
          <div className="flex flex-col items-center justify-center">
            <div
              className={`bg-primary relative aspect-video w-full ${
                isHomePage ? 'h-[224px]' : ''
              }`}
            >
              <LazyImage
                src={data?.image || bgAuthor}
                alt="Thumbnail course"
                fill
                sizes="(max-width: 768px) 100vw, (max-width: 768px) 50vw, 25vw"
                className="object-fill"
                style={{ borderRadius: '4px' }}
              />
            </div>
          </div>

          <div className="flex flex-col px-3">
            <div className="border-neutral-grayLight line-clamp-2 flex flex-col items-center py-2">
              <h3 className="text-PC-Title-Large lg:text-PC-Title-Large hover:text-primary-eastern line-clamp-2 py-1 pb-[8px] font-sans text-[#020509]">
                {data?.name}
              </h3>
              <h3 className="text-PC-Subtitle-Small text-neutral-gray line-clamp-3 pb-[16px] font-sans">
                Chuyên gia {data?.major}
              </h3>

              <span
                className="text-neutral-dark text-PC-Body-Medium line-clamp-6 text-justify leading-6"
                dangerouslySetInnerHTML={{
                  __html: data?.description || '',
                }}
              ></span>
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default AuthorCard;
